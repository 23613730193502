
































































































































































































































































































































































































































import { computed, defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: {
    ARegistrationLicense: () => import('@/components/atoms/a-registration-license.vue'),
    OScanner: () => import('@/components/organisms/o-scanner.vue')
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        registrationNumber: '',
        firstRegistrationDate: '',

        registrationCertificateHolderName: '',
        registrationCertificateHolderNumber: '',
        registrationCertificateHolderAddress: '',

        registrationCertificateOwnerName: '',
        registrationCertificateOwnerNumber: '',
        registrationCertificateOwnerAddress: '',

        vehicleBrand: '',
        vehicleModel: '',
        vehicleType: '',

        vinNumber: '',
        maximumGrossVehicleWeight: '',
        grossVehicleWeight: '',
        grossVehicleCombinationWeight: '',
        vehicleCurbWeight: '',
        registrationCertificateValidityPeriod: '',
        registrationCertificateIssueDate: '',
        vahicleCategory: '',
        approvalCertificateNumber: '',
        axlesNumber: '',
        maximumGrossTrailerWeightWithBrakes: '',
        maximumGrossTrailerWeightWithoutBrakes: '',
        engineCapacity: '',
        maximumEnginePower: '',
        fuelType: '',
        powerToWeightRatio: '',
        seatsNumber: '',
        standingPlacesNumber: '',
        vehicleKind: '',
        productionYear: '',
        loadCapacity: '',
        maximumAxleLoad: '',
        vehicleCardNumber: ''
      })
    }
  },

  setup(_, { root, emit }) {
    const date = new Date().toISOString().substr(0, 10)

    const state = reactive({
      loading: false,
      disabled: false,
      expand: false,
    })

    const rules = reactive({
      required: (value: any) => !!value || 'Pole wymagane.'
    })

    const disabled = computed(() => state.disabled)
    const loading = computed(() => state.loading)

    const onScan = (scan: any) => {
      // console.log(scan)
      root.$vuetify.goTo('#o-vehicle-data-form__required', {
        duration: 2500,
        offset: 0,
        easing: 'easeInOutCubic'
      })

      state.disabled = true

      emit('input', {
        registrationNumber: `${scan.Data.A || ''}`.trim(),
        firstRegistrationDate: `${scan.Data.B || ''}`.trim(),

        registrationCertificateHolderName: scan.Data.Posiadacz_Imie && scan.Data.Posiadacz_Nazwisko ? `${scan.Data.Posiadacz_Imie} ${scan.Data.Posiadacz_Nazwisko}` : `${scan.Data.Posiadacz_Dowodu}`.trim(),
        registrationCertificateHolderNumber: `${scan.Data.Posiadacz_Pesel || ''}`.trim(),
        registrationCertificateHolderAddress: `${scan.Data.Posiadacz_Kod || ''} ${scan.Data.Posiadacz_Miasto || scan.Data.Posiadacz_Gmina || ''}\n${scan.Data.Posiadacz_Ulica || scan.Data.Posiadacz_Miasto || ''} ${scan.Data.Posiadacz_Dom || ''}${scan.Data.Posiadacz_Klatka ? '/' + scan.Data.Posiadacz_Klatka : ''}`.trim(),

        registrationCertificateOwnerName: scan.Data.Wlasciciel_Imie && scan.Data.Wlasciciel_Nazwisko ? `${scan.Data.Wlasciciel_Imie} ${scan.Data.Wlasciciel_Nazwisko}` : `${scan.Data.Wlasciciel_Dowodu}`.trim(),
        registrationCertificateOwnerNumber: `${scan.Data.Wlasciciel_Pesel || ''}`.trim(),
        registrationCertificateOwnerAddress: `${scan.Data.Wlasciciel_Kod || ''} ${scan.Data.Wlasciciel_Miasto || scan.Data.Wlasciciel_Gmina || ''}\n${scan.Data.Wlasciciel_Ulica || scan.Data.Wlasciciel_Miasto || ''} ${scan.Data.Wlasciciel_Dom || ''}${scan.Data.Wlasciciel_Klatka ? '/' + scan.Data.Wlasciciel_Klatka : ''}`.trim(),

        vehicleBrand: `${scan.Data.D1 || ''}`.trim(),
        vehicleModel: `${scan.Data.D5 || ''}`.trim(),
        vehicleType: `${scan.Data.D2 || ''}\n${scan.Data.D3 || ''}\n${scan.Data.D4 || ''}`.trim(),

        vinNumber: `${scan.Data.E || ''}`.trim(),
        maximumGrossVehicleWeight: `${scan.Data['F.1'].replace('kg', '').replace(',', '.') || ''}`.trim(),
        grossVehicleWeight: `${scan.Data['F.2'].replace('kg', '').replace(',', '.') || ''}`.trim(),
        grossVehicleCombinationWeight: `${scan.Data['F.3'].replace('kg', '').replace(',', '.') || ''}`.trim(),
        vehicleCurbWeight: `${scan.Data.G.replace('kg', '').replace(',', '.') || ''}`.trim(),
        registrationCertificateValidityPeriod: `${scan.Data.H.replace('---', '') || ''}`.trim(),
        registrationCertificateIssueDate: `${scan.Data.I.replace('---', '') || ''}`.trim(),
        vahicleCategory: `${scan.Data.J || ''}`.trim(),
        approvalCertificateNumber: `${scan.Data.K || ''}`.trim(),
        axlesNumber: `${scan.Data.L || ''}`.trim(),
        maximumGrossTrailerWeightWithBrakes: `${scan.Data['O.1'].replace('kg', '').replace(',', '.') || ''}`.trim(),
        maximumGrossTrailerWeightWithoutBrakes: `${scan.Data['O.2'].replace('kg', '').replace(',', '.') || ''}`.trim(),
        engineCapacity: `${scan.Data['P.1'].replace('cm3', '').replace(',', '.') || ''}`.trim(),
        maximumEnginePower: `${scan.Data['P.2'].replace('KW', '').replace(',', '.') || ''}`.trim(),
        fuelType: `${scan.Data['P.3'] || ''}`.trim(),
        powerToWeightRatio: `${scan.Data.Q.replace('---', '') || ''}`.trim(),
        seatsNumber: `${scan.Data['S.1'].replace('---', '') || ''}`.trim(),
        standingPlacesNumber: `${scan.Data['S.2'].replace('---', '') || ''}`.trim(),
        vehicleKind: `${scan.Data.Rodzaj_pojazdu || ''}`.trim(),
        productionYear: `${scan.Data.Rok_produkcji || ''}`.trim(),
        loadCapacity: `${scan.Data.Dopuszczalna_ladownosc.replace('---', '') || ''}`.trim(),
        maximumAxleLoad: `${scan.Data.Dopuszczalny_nacisk_na_os.replace('kN', '').replace(',', '.') || ''}`.trim(),
        vehicleCardNumber: `${scan.Data.Numer_karty_pojazdu || ''}`.trim(),
      })
    }

    return { date, state, rules, disabled, loading, onScan }
  }
})
